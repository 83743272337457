.FeaturedItem {
    position: relative;
    background-color: $colorSecondary;
    margin-bottom: $margin16;
    margin-right: $margin16;
    margin-left: $margin16;
    padding-top: $margin24;
    padding-bottom: $margin24;
    padding-left: $margin48;
    padding-right: $margin48;
    font-size: $fontLarge;
    overflow: hidden;
    @include cornerRadius;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0.28;
    }

    p {
        position: relative;
        text-align: center;
    }

    // Default animation values
    opacity: 0;
    margin-top: $margin32;

    &.animate {
        transition-timing-function: ease;
        transition: all 500ms;
        opacity: 1;
        margin-top: 0;
    }

}
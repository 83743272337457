.ListItem {
    background-color: $colorSecondary;
    margin-bottom: $margin16;
    margin-right: $margin16;
    margin-left: $margin16;
    padding: $margin24;
    font-size: $fontNormal;
    overflow: hidden;
    @include cornerRadius;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        height: $margin24;
        width: $margin24;
    }

    p {
        flex: 1;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: $margin48;
        margin-left: $margin24;
        text-align: center;
    }

    // Default animation values
    opacity: 0;
    margin-top: $margin32;

    &.animate {
        transition-timing-function: ease;
        transition: all 500ms;
        opacity: 1;
        margin-top: 0;
    }

}
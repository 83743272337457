@import 'Colors';
@import 'Dimens';
@import 'Grid';
@import 'FeaturedItem';
@import 'ListItem';

html, body {
  font-family: 'Space Grotesk', sans-serif;
  background-color: $colorPrimary;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  line-height: $lineHeight;
  width: 100%;

  #root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error {
    position: absolute;
    color: $colorSecondary;
    text-align: center;
    font-size: $fontLarge;
    height: 100%;
    top: 0;
    bottom: 0;
    left: $margin48;
    right: $margin48;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a:link {
    text-decoration: none;
    color: inherit;
  }
  
  a:visited {
    text-decoration: none;
    color: inherit;
  }
  
  a:hover {
    text-decoration: none;
    color: inherit;
  }
  
  a:active {
    text-decoration: none;
    color: inherit;
  }

  .isLast {
    margin-bottom: 0;
  }

}
